import axios from './index.js'


export function getInsurance(idToken) {
  return axios.get('/insurance/getInsurance', {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message); // "oh, no!"
     return e.message;
  });
}

export function addNewInsurance(idToken, newInsurancePayload) {
  return axios.post('/insurance/addInsurance', newInsurancePayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}

export function addNewInsuranceFile(idToken, newInsurancePayload) {
  return axios.post('/insurance/addInsuranceFile', newInsurancePayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}

export function deleteInsurance(idToken, InsurancePayload) {
  return axios.post('/insurance/deleteInsurance',InsurancePayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}

export function updateInsurance(idToken, InsurancePayload) {
  return axios.post('/insurance/updateInsurance', InsurancePayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}


export function getInsuranceSummary(idToken, insurancePayload) {
  return axios.post('/insurance/getSummary', insurancePayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(response => {
      return response.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}
