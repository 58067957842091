import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB0S-ph1I9ZKswD77KLprxjXGI_fuG74m4",
  authDomain: "housemanager-3e518.firebaseapp.com",
  projectId: "housemanager-3e518",
  storageBucket: "housemanager-3e518.appspot.com",
  messagingSenderId: "1003612147851",
  appId: "1:1003612147851:web:b2d76596cafef0d369c48e",
  measurementId: "G-E53MTXS6Y6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Cloud Firestore
const db = getFirestore(app);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

export { auth, db, analytics };


