import { getInsurance, addNewInsurance, addNewInsuranceFile, updateInsurance, deleteInsurance, getInsuranceSummary } from '@/Api/insurance.js';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata   } from "firebase/storage";


export default {
  namespaced: true,
  state () {
    return {
      insurance: [],
      originalInsurance: [],				
      insuranceSummary: {'Renewal Required': 0, 'In Review': 0, 'Missing File': 0, 'Compliant': 0, 'Not Compliant': 0},
      permissionsInfo: {
      getDataPermission: false,
      addFilePermission: false,
      getDeletePermission: false,
      getAllDataPermission: false,
      }
    }
  },
  mutations: {
    insuranceInfo (state, insurance) {
      state.insurance = insurance
    },
    insuranceSummaryInfo (state, insuranceSummary) {
      state.insuranceSummary = insuranceSummary
    },
    originalInsuranceInfo (state, originalInsurance) {
      state.originalInsurance = originalInsurance
    },
    DataPermission (state, getDataPermission) {
      state.permissionsInfo.getDataPermission = getDataPermission
    },
    FilePermission (state, addFilePermission) {
      state.permissionsInfo.addFilePermission = addFilePermission
    },
    deletePermission (state, getDeletePermission) {
      state.permissionsInfo.getDeletePermission = getDeletePermission
    },
    AllDataPermission (state, getAllDataPermission) {
      state.permissionsInfo.getAllDataPermission = getAllDataPermission
    },
    EditAllPermission (state, getEditAllPermission) {
      state.permissionsInfo.getEditAllPermission = getEditAllPermission
    }
  },
  actions: {
    async getInsurance({ state, rootState, commit }) {
      let res = await getInsurance(rootState.auth.user.accessToken);
      if(!res.status) {
        console.log("failed", res.data);
        return false;
      }
      commit('insuranceInfo', res.data);
      commit('originalInsuranceInfo', res.data);
    }, 

    async addInsurance({ state, rootState, commit }, insurancePayload) {
      let res = await addNewInsurance(rootState.auth.user.accessToken, insurancePayload);
      if(!res.status) {
        console.log("res.status failed", res.status);
        return false;
      }
      commit('insuranceInfo', [...res.data, ...state.insurance])
      return true;
    },

    async addInsuranceFile({ state, rootState, commit }, insurancePayload) {
      let res = await addNewInsuranceFile(rootState.auth.user.accessToken, insurancePayload);
      if(!res.status) {
        console.log("res.status failed", res.status);
        return false;
      }
      commit('insuranceInfo', res.data);
      return true;
    },

    async deleteInsurance({ state, rootState, commit }, insurancePayload) {
      let res = await deleteInsurance(rootState.auth.user.accessToken, insurancePayload);
      if(!res.status) {
        console.log("failed", res.data);
        return false;
      }
      commit('insuranceInfo', res.data);
    },

    async updateInsurance({ state, rootState, commit }, insurancePayload) {
      let res = await updateInsurance(rootState.auth.user.accessToken, insurancePayload);
      if(!res.status) {
        console.log("failed", res.data);
        return false;
      }
      commit('insuranceInfo', res.data);
      return true;
    },
    
    async prepURL({ state, rootState, commit }) {
      // create URL based on the location and storage from the insurance prop for each row and save it into the location field
      // if storage is missing or empty, replace location with "mising file" string
      // if storageRef is not empty, create URL and replace location with URL using ref(this.storage, location);
      // if URL creation fails, replace location with "missing file" string
      // return the updated insurance prop
      const globalStorage = getStorage();
      let insurance = state.insurance;
      insurance.forEach((row) => {
        if (row.location === null || row.location === '') {
          row.location = '';
        } else {
          const location = row.location;
          //const pathReference = ref(globalStorage, storage);
          //console.log('pathReference', pathReference);
          const storageRef = ref(globalStorage, location);
          getDownloadURL(storageRef)
            .then((url) => {
              row.url = url;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      );
      commit('insuranceInfo', insurance);

      

    },
    async initAllowedActions({ state, rootState, commit }) {
      const allowedRoleForRole = rootState.auth.routesByRole[rootState.auth.user.role];
      if(allowedRoleForRole.insurance.length === 1 && allowedRoleForRole.insurance[0] === '*'){
        commit('DataPermission', true);
        commit('FilePermission', true);
        commit('deletePermission', true);
        commit('AllDataPermission', true);
        commit('EditAllPermission', true);
      } else {
        allowedRoleForRole.insurance.forEach((action) => {
        if (action === 'getDocuments') {
          commit('DataPermission', true);
        } else if (action === 'deleteDocuments') {
          commit('deletePermission', true);
        } else if (action === 'addFile') {
          commit('FilePermission', true);
        } else if (action === 'getAllData') {
          commit('AllDataPermission', true);
        } else if (action === 'editAll') {
          commit('EditAllPermission', true);
        }
      })
    };
  },

    filterInsuranceData({ state, rootState, commit }, search) {
      if (search.searchValue === '') {
          commit('insuranceInfo', state.originalInsurance);
      } else {
        const filteredInsurance =  state.originalInsurance.filter(row => {
          // Check if any property of the row contains the search value
          return Object.values(row).some(value => {
                if (typeof value === 'object') {
                    // Check if the object contains the search value
                    const stringifiedValue = JSON.stringify(value)
                    return stringifiedValue.toLowerCase().includes(search.searchValue.toLowerCase())
                } else {
                    return String(value).toLowerCase().includes(search.searchValue.toLowerCase())
                }
          })
        })
        commit('insuranceInfo', filteredInsurance);
      }
    },

    async getInsuranceSummary({ state, rootState, commit }, insurancePayload) {
      let res = await getInsuranceSummary(rootState.auth.user.accessToken, insurancePayload);
      if(!res.status) {
        console.log("failed", res.data);
        return false;
      }
      // for each key in the prop object, replace the value with the value from the response
      Object.keys(res.data).forEach((key) => {
        state.insuranceSummary[key] = res.data[key];
      }
      );

      
    },
  }
}
