import { auth } from '../firebaseConfig';
import * as firebase from "firebase/app";
import { onAuthStateChanged ,signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "firebase/auth";
import {handleSignIn,  isUserLoggedIn, resgiterNewApi } from '@/Api/user.js';
import {getPersonalInfo} from '@/Api/profile.js';
import jwt_decode from 'jwt-decode';
import router from './../router/index.js'
import {fetchAllowedRolesForRoutes } from '@/Api/user.js';
import { sendPasswordResetEmail } from "firebase/auth"

export default {
  state () {
    return {
      user: null,
      loggedIn: false,
      routesByRole: [],
      allowedRolesUpdated: false,
      roleName: null,
      initialRoute: '',
      loginRoutes: ['dashboard', 'maintenance', 'projects', 'calendar', 'transactions', 'building', 'documents', 'documentsBuilding',
                     'deliveries', 'services', 'forum', 'boardForum', 'insurance'],
      loginEvent: false,
      signInloading: false,
      token_refresh_interval: 1000 * 60 * 60, // 60 minutes
      token_timestamp: null,
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value
    },
    SET_ROUTES(state, routesByRole) {
      state.routesByRole = routesByRole
    },
    SET_ALLOW_ROLE_UPDATE(state, isUpdated) {
      state.allowedRolesUpdated = isUpdated
    },
    setRoleName (state, roleName) {
      state.roleName = roleName
    },
    setInitialRoute (state, initialRoute) {
      state.initialRoute = initialRoute
    },
    setLoginEvent (state, loginEvent) {
      state.loginEvent = loginEvent
    },
    setSignInloading (state, signInloading) {
      state.signInloading = signInloading
    },

    updateAccessToken(state, newToken) {
      state.user.accessToken = newToken;
    },
    setTokenTimestamp(state, timestamp) {
      state.token_timestamp = timestamp;
    }

  },
  getters: {
    user (state) {
      return state.user
    },
    allowedRoutes (state) {
      return state.routesByRole[state.roleName]
    }
  },
  actions: {

    setLoggedIn({ commit }, value) {
      if (process.env.NODE_ENV === 'development') {
        console.log('setLoggedIn', value)}
      commit('SET_LOGGED_IN', value)
    },

    async getUserToken({ state, commit, dispatch }) {
      // run only if token is expired or not exist
      
      if(!state.user.accessToken || state.token_timestamp == null || Date.now() - state.token_timestamp > state.token_refresh_interval) {
        if (process.env.NODE_ENV === 'development') {
         console.log('token is expired', Date.now(), state.token_timestamp, state.token_refresh_interval, state.user.accessToken);}
      const user = auth.currentUser;
      if(!user) return null;
  
      try {
          const refreshedToken = await user.getIdToken();  // true if want to forces a refresh
          commit('updateAccessToken', refreshedToken);
          commit('setTokenTimestamp', Date.now());
          return refreshedToken;
      } catch(error) {
          console.error("Error getting token: ", error);
          return null;
      }
    }
    else {
      console.log('token is not expired', Date.now() - state.token_timestamp, state.token_refresh_interval);
      return state.user.accessToken;
    }
  },

    async isLoggedIn({ state, commit, dispatch }) {
      if (process.env.NODE_ENV === 'development') {
        console.log('isLoggedIn')}
      console.log('state.loggedIn', state.loggedIn)
      if(state.loggedIn && state.user && state.user.accessToken && state.token_timestamp != null && Date.now() - state.token_timestamp < state.token_refresh_interval) {
        return true;
      }

      return new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if(!user){
            if (process.env.NODE_ENV === 'development') {
              console.log('user - signOut', user)}
            dispatch('signOut')
            resolve(false);
            return;
          }
          commit('setUser', user);
          const refreshedToken = await dispatch('getUserToken');
            if (!refreshedToken) {
                if (state.loginEvent) {
                    commit('setSignInloading', false);
                }
                dispatch('signOut');
                resolve(false);
                return;
            }

          let isSignedIn = state.loginEvent ? await handleSignIn(refreshedToken) : true;

          console.log("isSignedIn", isSignedIn);
          if(isSignedIn) {

            if(!state.roleName || state.loginEvent) {
               await dispatch('handlePersonalInfo')
            }

            await dispatch('getRoutes');
            await dispatch('updateInitialRoute');
            if(state.loginEvent) {
              router.push({ name: state.initialRoute });
              commit('setSignInloading', false);
            }
            commit('setLoginEvent', false);

            dispatch('setLoggedIn', true)
            unsubscribe();
            resolve(true);
            // const isLogged = await isUserLoggedIn(user.accessToken, idToken)
          } else {
            if(state.loginEvent) {
              commit('setSignInloading', false);
            }
            dispatch('signOut')
            resolve(false);
          }
        }, reject);
      });
    },

    async getRoutes({state, commit, dispatch}) {
      if (process.env.NODE_ENV === 'development') {
        console.log('getRoutes')}
      let allowedRoutesByRole = await fetchAllowedRolesForRoutes(state.user.accessToken);

      router.options.routes.forEach(route => {
        if(!route.meta.requiresAuth || route.meta.allowedRoles.length > 0){
          return;
        }
        Object.entries(allowedRoutesByRole).forEach(([roleName, allowedRolesByRoute]) => {
          let routerName = route.meta.permissionName;

          if(Object.keys(allowedRolesByRoute).includes(routerName)) {
            route.meta.allowedRoles.push(roleName);
          }
        });
      });

      commit('SET_ROUTES', allowedRoutesByRole);
      commit('SET_ALLOW_ROLE_UPDATE', true);

      return allowedRoutesByRole
    },

    async handlePersonalInfo({state, commit, dispatch}) {
      let user = state.user;
      let userPersonalres = await getPersonalInfo(user.accessToken);
      if(!userPersonalres.status || !userPersonalres.data) {
        console.log('userPersonalres - false', userPersonalres);
        dispatch('signOut')
        return false
      };
      user['building_id'] = userPersonalres.data.building_id;
      user['role'] = userPersonalres.data.role;
      commit('setRoleName', userPersonalres.data.role);
      commit('setUser', user);
      return true
    },

    async registrationHandler({state, commit, dispatch}, data) {
     
      if(data.regType === 'selfRegistration') {
        return createUserWithEmailAndPassword(auth, data.email, data.password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            commit('setUser', user);

            let isSignedIn = await resgiterNewApi(user);
            if(isSignedIn) {
              isSignedIn = await handleSignIn(state.user.accessToken);
            }

            dispatch('setLoggedIn', isSignedIn)
            await dispatch('handlePersonalInfo')
            return isSignedIn;
          })
          .catch((error) => {
            dispatch('setLoggedIn', false)
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('errorUemail', error);
            return false;
          });
      } else {
        let isSignedIn = await dispatch('signInWithPopup')
        if(isSignedIn) {
          isSignedIn = await handleSignIn(state.user.accessToken);
          await dispatch('handlePersonalInfo')
        }

        dispatch('setLoggedIn', isSignedIn)
        return isSignedIn;
      }
    },
    async loginHandler({state, commit, dispatch}, data) {
      try {
          commit('setSignInloading', true);
          commit('setLoginEvent', true);
          let isSignedIn = false;
          if(data.regType === 'selfRegistration') {
              const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password).catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log('errorUemail', error);
                  commit('setSignInloading', false);
                  return false;
              });
              const user = userCredential.user;
              commit('setUser', user);
              isSignedIn = !!userCredential;
          } else {
              isSignedIn = await dispatch('signInWithPopup');
          }

          dispatch('setLoggedIn', isSignedIn);
          if(state.loggedIn) {
            dispatch('logAnalytics');
          }
          return state.loggedIn;
      } catch(error) {
          console.error("Error occurred during sign-in: ", error);
          return false;
      }
  },




    async signInWithPopup({state, commit}) {
      let provider = new GoogleAuthProvider();
      return await signInWithPopup(auth, provider)
        .then(async (result) => {

          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = await GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          commit('setUser', user);
          return true;

        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log("errorMessage", errorMessage);
          commit('setSignInloading', false);
          return false;
        });
    },

    async updateInitialRoute({commit, state, dispatch, rootState}) {
      for (let i = 0; i < state.loginRoutes.length; i++) {
        let route = state.loginRoutes[i];

        if(!state.routesByRole || state.routesByRole.length === 0) {
          console.log('!state.routesByRole: ', state.routesByRole);
          await dispatch('getRoutes');
        }
        if(!state.roleName) {
          console.log('!state.roleName: ', state.roleName);
          await dispatch('handlePersonalInfo')
        }
        const userValidRoutes = state.routesByRole[state.roleName]
        if(!userValidRoutes) {
          console.log('state.routesByRole[state.roleName] - null ', state.roleName, state.routesByRole);
          await dispatch('getRoutes');
          await dispatch('handlePersonalInfo')
        }

        if(Object.keys(userValidRoutes).includes(route)) {
          const matchingRoute = router.options.routes.find(routeName => {
            return routeName.meta && routeName.meta.permissionName === route;
          })
          if(Object.keys(matchingRoute).length > 0) {
            localStorage.setItem("initialRoute", matchingRoute.path);
            commit('setInitialRoute', matchingRoute.name);
            return;
          }
        }
      }
      await dispatch('signOut', false)
    },

    async resetPassword({state, commit, dispatch},data) {
      console.log('resetPassword');
      return sendPasswordResetEmail(auth, data.email_address)
						.then(() => {
              console.log('success');
              return 'success';
						})
						.catch((error) => {
              console.log('error', error);
              return 'something went wrong';
						})
    },

    async signOut({state, commit, dispatch}) {
      return auth.signOut().then(function() {
        commit('setUser', null);
        dispatch('setLoggedIn', false)
        return true;
      }, function(error) {
        dispatch('setLoggedIn', false);
        return false;
        console.error('Sign Out Error', error);
      });
    },

    logAnalytics({state}) {
      analytics.identify(state.user['email'], {
        "id": state.user['email'],
        "company_id": state.user['building_id'],
        "email": state.user['email'],
        "name": state.user['displayName'],
      });
    }

  }
}
