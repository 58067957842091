import { getMaintenance, addNewMaintenance, getMaintenanceThread, addNewComment, deleteComment, updateComment, updateMaintenance, deleteMaintenance } from '@/Api/maintenance.js';


export default {
  namespaced: true,
  state () {
    return {
      maintenance: [],
      originalMaintenance: [],
      permissionsInfo: {
        editMaintenancePermission: false,
      },
      comments_data: [],
      maintenanceIssue: {},
    }
  },
  mutations: {
    maintenanceInfo (state, maintenance) {
      state.maintenance = maintenance
    },
    maintenanceIssueInfo (state, maintenanceIssue) {
      state.maintenanceIssue = maintenanceIssue
    },
    originalMaintenanceInfo (state, originalMaintenance) {
      state.originalMaintenance = originalMaintenance
    },
    editPermission (state, editMaintenancePermission) {
      state.permissionsInfo.editMaintenancePermission = editMaintenancePermission
    },
    deletePermission (state, deleteMaintenancePermission) {
      state.permissionsInfo.deleteMaintenancePermission = deleteMaintenancePermission
    },
    statusPermission (state, statusMaintenancePermission) {
      state.permissionsInfo.statusMaintenancePermission = statusMaintenancePermission
    },
    updateThreadComments(state, newComment) {
      state.comments_data = [...state.comments_data, newComment];
    },
    commentsInfo (state, comments_data) {
      state.comments_data = comments_data
    },
  },
  actions: {
    async getMaintenance({ state, rootState, commit }) {
      let res = await getMaintenance(rootState.auth.user.accessToken);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('maintenanceInfo', res.data);
      commit('originalMaintenanceInfo', res.data);
    },

    async getMaintenanceThread({ state, rootState, commit }, maintenancePayload) {
      let res = await getMaintenanceThread(rootState.auth.user.accessToken, maintenancePayload);
      if(!res.status) {
        return false;
      }
      commit('maintenanceIssueInfo', res.data);
      commit('commentsInfo', res.data.comments);
    },

    async addMaintenance({ state, rootState, commit }, maintenancePayload) {
      let res = await addNewMaintenance(rootState.auth.user.accessToken, maintenancePayload);
      if(!res.status) {
        console.log("res.status faield", res.status);
        return false;
      }
      commit('maintenanceInfo', res.data);
      return true;
    },

    async deleteMaintenance({ state, rootState, commit }, maintenancePayload) {
      let res = await deleteMaintenance(rootState.auth.user.accessToken, maintenancePayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('maintenanceInfo', res.data);
    },

    async updateMaintenance({ state, rootState, commit }, maintenancePayload) {
      let res = await updateMaintenance(rootState.auth.user.accessToken, maintenancePayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('maintenanceInfo', res.data);
      return true;
    },

    async addComment({ state, rootState, commit }, commentPayload) {
      let res = await addNewComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("addNewComment faield", res.data);
        return false;
      }
      commit('updateThreadComments', res.data);
      return true;
    },

    async deleteComment({ state, rootState, commit }, commentPayload) {
      let res = await deleteComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('maintenanceIssueInfo', res.data);
      commit('commentsInfo', res.data.comments);
      return true;
    },

    async updateComment({ state, rootState, commit }, commentPayload) {
      let res = await updateComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('commentsInfo', res.data);
      return true;
    },

    async initAllowedActions({ state, rootState, commit }) {
      const allowedRoleForRole = rootState.auth.routesByRole[rootState.auth.user.role];
      if(allowedRoleForRole.maintenance === 'admin') {
        commit('editPermission', true);
        commit('deletePermission', true);
        commit('statusPermission', true);
      }
    },

    filterMaintenanceData({ state, rootState, commit }, search) {
      if (search.searchValue === '') {
          commit('maintenanceInfo', state.originalMaintenance);
      } else {
        const filteredMaintenance =  state.originalMaintenance.filter(row => {
          // Check if any property of the row contains the search value
          return Object.values(row).some(value => {
                if (typeof value === 'object') {
                    // Check if the object contains the search value
                    const stringifiedValue = JSON.stringify(value)
                    return stringifiedValue.toLowerCase().includes(search.searchValue.toLowerCase())
                } else {
                    return String(value).toLowerCase().includes(search.searchValue.toLowerCase())
                }
          })
        })
        commit('maintenanceInfo', filteredMaintenance);
      }
    }

  }
}
